import React, { memo, useCallback, useEffect, useState } from "react";

import {
  TEXT_COLOR,
  UTILITY_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { Metadata } from "@hl/shared-features/lib/features/gen-art/preview";
import { logError } from "@hl/shared-features/lib/services/logger";
import { createStyles, keyframes, Stack, Title } from "@mantine/core";
import pRetry from "p-retry";

import { GenArtMintedView } from "../../token-details/GenArtMinted";

import { TokenRevealMedia } from "./TokenRevealMedia";

export const loadingTextAnimation = keyframes({
  to: { backgroundPosition: "0% center" },
});

const useStyles = createStyles((theme) => {
  return {
    genArtToolbar: {
      position: "absolute",
      bottom: 20,
    },
    loadingTitle: {
      backgroundPosition: "100% center",
      backgroundSize: "220% 100%",
      animation: `${loadingTextAnimation} 2.5s cubic-bezier(0.65, 0, 0.35, 1) 0s infinite normal`,
      backgroundImage: `linear-gradient(to right, ${theme.fn.themeColor(
        TEXT_COLOR.TERTIARY
      )} 0%, ${theme.fn.themeColor(
        TEXT_COLOR.TERTIARY
      )} 45%, ${theme.fn.themeColor(
        UTILITY_COLOR.DIVIDER
      )} 50%, ${theme.fn.themeColor(
        UTILITY_COLOR.DIVIDER
      )} 50%, ${theme.fn.themeColor(
        TEXT_COLOR.TERTIARY
      )} 55%, ${theme.fn.themeColor(TEXT_COLOR.TERTIARY)} 100%)`,
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
    },
  };
});

export type MintedTokenDisplayInfo = {
  tokenId: string;
  collectionOnChainBaseUri: string;
  isGenerative: boolean;
  onLoaded: (metadata: Metadata) => void;
  height?: number;
  overrideImageUrl?: string;
};

export const TokenRevealCarouselSlide = ({
  tokenId,
  collectionOnChainBaseUri,
  isGenerative,
  onLoaded,
  height,
  overrideImageUrl,
}: MintedTokenDisplayInfo) => {
  const [loadingImage, setLoadingImage] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<Metadata>();
  const { classes } = useStyles();
  const controller = new AbortController();

  const fetchMetadata = useCallback(async () => {
    const response = await fetch(`${collectionOnChainBaseUri}/${tokenId}`);
    const data: Metadata = await response.json();
    setLoadingImage(false);
    setMetadata(data);
    onLoaded(data);
  }, []);

  useEffect(() => {
    pRetry(fetchMetadata, { signal: controller.signal }).catch((error) =>
      logError(error, "Error fetching metadata on series")
    );
    return () => controller.abort();
  }, []);

  if (loadingImage)
    return (
      <Stack
        align="center"
        justify="center"
        w="100%"
        h="100%"
        bg={UTILITY_COLOR.TABLE_ROW_BACKGROUND}
        sx={(theme) => ({
          border: `0.5px solid ${theme.colors.divider[0]}`,
        })}
      >
        <Title order={6} className={classes.loadingTitle} align="center">
          Revealing artwork...
        </Title>
      </Stack>
    );

  if (!metadata) return <></>;
  if (isGenerative)
    return (
      <GenArtMintedView
        animationUrl={metadata.animation_url ?? ""}
        toolbarExtraClass={classes.genArtToolbar}
        showToolbarOnHover
        isReveal
        tokenId={tokenId}
      />
    );
  return (
    <TokenRevealMedia
      customHeight={height}
      animationUrl={metadata.animation_url}
      imageUrl={overrideImageUrl ?? metadata.image}
    />
  );
};

export default memo(TokenRevealCarouselSlide);
